<template>
  <template v-if="show">
    <slot />
  </template>
  <template v-else>
    <slot name="placeholder" />
  </template>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';

const show = ref(false);

onMounted(() => {
  show.value = true;
});
</script>
